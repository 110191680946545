import React from "react";
import { Grid } from "@mui/material";
import ActionButtons from "../base/ActionButtons";
import { useDispatch, useSelector } from "../../store";
import { handleSuccessToastState } from "../../slices/toast";
import SnackBarToast from "../base/SnackbarToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentMedical } from "@fortawesome/free-solid-svg-icons";

interface Props {
  handleModal: () => void;
}

const CaseMenu: React.FC<Props> = (props) => {
  const { handleModal } = props;

  const dispatch = useDispatch();
  const { successToastOpen } = useSelector((store) => store.toast);

  const closeToast = () => {
    dispatch(handleSuccessToastState(false));
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item>
          <ActionButtons
            label={"Contact Provider"}
            icon={<FontAwesomeIcon icon={faCommentMedical} size="1x" />}
            onClick={handleModal}
          />
        </Grid>
      </Grid>

      <SnackBarToast message="Success!" type="success" open={successToastOpen} handleClose={closeToast} />
    </React.Fragment>
  );
};

export default CaseMenu;
