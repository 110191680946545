import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  Fab,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "../../store";
import Conversation from "../../types/conversation";
import ActionButtons from "../base/ActionButtons";
import { Add, ForumRounded, Group, Close, PushPin } from "@mui/icons-material";
import User from "../../types/user";
import ChatComponent from "./ChatComponent";
import { clearConversation, getMessages, getConversations, toggleChat, setPinnedChat } from "../../slices/chat";
import { fetchUsers } from "../../slices/users";
import { useIdleTimer } from "react-idle-timer";
import getUserRoleId from "../../helpers/getUserRoleId";
import theme from "../../theme";
import Avatar from "../base/Avatar";
import UserSimple from "../../types/userSimple";
import BadgeIcon from "../base/BadgeIcon";
import { clearTeamConversation, getTeamMessages, setPinnedTeamChat, toggleTeamChat } from "../../slices/teamchats";
import S3Avatar from "../base/S3Avatar";
import { clearTechConversation, toggleTechChatHospital } from "../../slices/techChats";

interface Props {
  conversations: Conversation[];
  teamConversations: Conversation[];
  loggedInUser: User;
}

const ChatMenu: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { chatOpen, pinnedChat } = useSelector((store) => store.chat);
  const { loggedInUser } = useSelector((store) => store.auth);
  const { teamChats, pinnedTeamChat, teamChatOpen } = useSelector((store) => store.teamChats);
  const { usersCollapsed } = useSelector((store) => store.users);
  const [openList, setOpenList] = useState<boolean>(false);
  const [conversations, setConversations] = useState(props.conversations);
  const [unreadConversations, setUnreadConversations] = useState(0);
  const [unreadTeam, setUnreadTeam] = useState(0);
  const [width, setWidth] = useState(0);
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  const handleResize = () => {
    setWidth(document.getElementById("pending-cases")?.clientWidth!);
  };

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    if (width === 0 || undefined) {
      const interval = setInterval(() => {
        handleResize();
      }, 5000);
      return () => clearInterval(interval);
    } else if (width !== document.getElementById("pending-cases")?.clientWidth!) {
      handleResize();
    }
  }, [width, usersCollapsed]);

  useEffect(() => {
    setConversations(props.conversations);
    let total = 0;
    let totalTeam = 0;

    for (const conv of props.conversations) {
      total += conv?.unread!;
    }
    for (const conv of props.teamConversations) {
      totalTeam += conv?.unread!;
    }
    setUnreadConversations(total);
    setUnreadTeam(totalTeam);
  }, [props.conversations, props.teamConversations, width]);

  const handleOpenList = (): void => {
    setOpenList(true); // open current conversation box
  };

  const handleCloseList = (): void => {
    dispatch(getConversations());
    if (pinnedChat) {
      return;
    } else {
      setOpenList(false); // close current conversations box
    }
  };

  const handleOpenChat = (id: number, name: string) => {
    dispatch(clearConversation());
    if (id > 0) {
      dispatch(getMessages(id));
    } else {
      dispatch(fetchUsers({ includeTechs: false, includeAdmins: true }));
    }
    setOpenList(false); // closes current conversation list if not pinned
    dispatch(toggleChat({ id: id, title: name, open: true, team: false }));
  };

  const handleOpenTeamChat = () => {
    dispatch(clearTeamConversation());
    dispatch(getTeamMessages());
    setOpenList(false); // closes current conversation list if not pinned
    dispatch(toggleTeamChat(true));
  };

  const handleCloseIndividualChat = (): void => {
    dispatch(clearConversation());
    dispatch(clearTechConversation());
    dispatch(toggleTechChatHospital(undefined));
    dispatch(toggleChat({ id: undefined, title: "", open: false, team: false, tech: false }));
  };

  const handleCloseTeamChat = (): void => {
    if (pinnedTeamChat && teamChatOpen === true) {
      return;
    } else {
      dispatch(clearTeamConversation());
      dispatch(toggleTeamChat(false));
    }
  };

  const handleClosePinnedTeam = () => {
    dispatch(setPinnedTeamChat(false));
    dispatch(clearTeamConversation());
    dispatch(toggleTeamChat(false));
  };

  const handleOnIdle = () => {
    handleCloseTeamChat();
    handleCloseIndividualChat();
    handleCloseList();
  };

  useIdleTimer({
    timeout: 1000 * 30 * 1,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <>
      {getUserRoleId(loggedInUser) !== 3 && (
        <>
          {/* regular chat icon bottom right of screen */}
          <Tooltip title={<Typography variant="button">Chat</Typography>}>
            <Fab
              color={unreadConversations > 0 ? "secondary" : "primary"}
              size="large"
              ref={anchorRef}
              sx={{
                bottom: "-30px",
                margin: (theme) => theme.spacing(4),
                position: "fixed",
                right: "-30px",
                zIndex: 3000,
                color: "white",
              }}
              onClick={handleOpenList}
            >
              <BadgeIcon
                color={"error"}
                badgeContent={unreadConversations!}
                content={<ForumRounded fontSize="large" />}
              />
            </Fab>
          </Tooltip>

          {/* Team chat drawer */}
          <Drawer
            sx={{
              "& .MuiDrawer-paper": {
                width: { xs: window.innerWidth, md: width },
                borderRadius: "15px",
                boxSizing: "border-box",
                position: "fixed",
                left: {
                  xs: 0,
                  md:
                    window.location.href.includes("dashboard") &&
                    !window.location.href.includes("team") &&
                    !usersCollapsed
                      ? 365
                      : window.location.href.includes("dashboard") &&
                        !window.location.href.includes("team") &&
                        usersCollapsed
                      ? 190
                      : 0,
                },
                bottom: 0,
                overflow: "hidden",
                zIndex: { xs: teamChatOpen ? 4000 : 0, md: undefined },
              },
            }}
            open={teamChatOpen || pinnedTeamChat}
            anchor="bottom"
            variant="persistent"
            onFocus={handleCloseIndividualChat}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: 1,
                padding: 1,
              }}
            >
              <Box>
                <Typography variant={"h6"}>Reading Room</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box>
                  {!sm && (
                    <ActionButtons
                      onClick={() => dispatch(setPinnedTeamChat(!pinnedTeamChat))}
                      label={pinnedTeamChat ? "Unpin" : "Pin"}
                      icon={<PushPin sx={{ transform: pinnedTeamChat ? "rotate(-90deg)" : "" }} />}
                    />
                  )}
                </Box>
                <Box>
                  <ActionButtons
                    onClick={() => {
                      if (teamChatOpen === true) {
                        handleClosePinnedTeam();
                      } else {
                        handleCloseTeamChat();
                      }
                    }}
                    label="Close"
                    icon={<Close />}
                  />
                </Box>
              </Box>
            </Box>
            <ChatComponent chatId={0} open={teamChatOpen === true} teamChat={true} techChat={false} />
          </Drawer>
          {/* This is the bottom menu item for team chat, which needs to be closed if team chat is open */}
          <Fragment key={0}>
            {!teamChatOpen && width > 0 ? (
              <>
                {!!teamChats?.find((teamMember: UserSimple) => teamMember.id === loggedInUser?.id) && (
                  <MenuItem
                    onClick={handleOpenTeamChat}
                    sx={{
                      bottom: 5,
                      marginLeft: { xs: 0, md: 4 },
                      position: "fixed",
                      left: {
                        xs: 5,
                        md:
                          window.location.href.includes("dashboard") &&
                          !window.location.href.includes("team") &&
                          !usersCollapsed
                            ? 332
                            : window.location.href.includes("dashboard") &&
                              !window.location.href.includes("team") &&
                              usersCollapsed
                            ? 157
                            : 0,
                      },
                      zIndex: 2000,
                      border: "1px solid black",
                      borderRadius: "25px",
                      backgroundColor: unreadTeam > 0 ? theme.palette.secondary.main : theme.palette.primary.main,
                      width: { xs: window.innerWidth - 75, md: width },
                      padding: "10px 10px",
                      "&:hover": {
                        backgroundColor: unreadTeam > 0 ? theme.palette.secondary.main : theme.palette.primary.main,
                        opacity: ".60",
                      },
                    }}
                    disabled={!!teamChatOpen}
                  >
                    <Box
                      component={"span"}
                      sx={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        mr: 1.5,
                      }}
                    >
                      <ListItemIcon>
                        <Group />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography color="textPrimary" variant="subtitle2">
                            Reading Room
                          </Typography>
                        }
                      />
                      <BadgeIcon color="error" badgeContent={unreadTeam} />
                    </Box>
                  </MenuItem>
                )}
              </>
            ) : null}
          </Fragment>
          {/* This is the current conversations box */}
          <Box>
            <Drawer
              open={openList || pinnedChat}
              anchor="bottom"
              sx={{
                "& .MuiDrawer-paper": {
                  width: 300,
                  borderRadius: "15px",
                  position: "fixed",
                  left: window.innerWidth - 300,
                  zIndex: 4000,
                  p: 1,
                },
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
              variant="persistent"
            >
              <Box justifyContent="space-between" flexDirection="row" display="flex" alignItems="center">
                <Box>
                  <Typography color="textPrimary" variant="subtitle1">
                    Current Conversations
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box>
                    {!sm && (
                      <ActionButtons
                        onClick={() => dispatch(setPinnedChat(!pinnedChat))}
                        label={pinnedChat ? "Unpin" : "Pin"}
                        icon={<PushPin sx={{ transform: pinnedChat ? "rotate(-90deg)" : "" }} />}
                      />
                    )}
                  </Box>
                  <Box>
                    <ActionButtons label="New Chat" icon={<Add />} onClick={() => handleOpenChat(-1, "New Chat")} />
                  </Box>
                  <Box>
                    <ActionButtons
                      onClick={() => {
                        if (pinnedChat) {
                          dispatch(setPinnedChat(false));
                          setOpenList(false);
                        } else {
                          setOpenList(false);
                        }
                      }}
                      label="Close"
                      icon={<Close />}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                {conversations &&
                  conversations.map((c: Conversation) => (
                    <Fragment key={c.id}>
                      <Divider />
                      {c.isTeam || c.isTech ? null : (
                        <MenuItem onClick={() => handleOpenChat(c.id, c.name)}>
                          <>
                            <ListItemIcon>
                              <Avatar alt={c.name}>
                                <S3Avatar firstName={c.name} lastName={""} imageId={c.id} height="40px" />
                              </Avatar>
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography color="textPrimary" variant="subtitle2">
                                  {c.name}
                                </Typography>
                              }
                            />
                          </>
                          <Box sx={{ mr: 1.5 }}>
                            <BadgeIcon color="error" badgeContent={c.unread!} />
                          </Box>
                        </MenuItem>
                      )}
                    </Fragment>
                  ))}
              </Box>
            </Drawer>
          </Box>
        </>
      )}
      {/* Individual chat */}
      <Box>
        <Drawer
          open={chatOpen.open}
          anchor="bottom"
          sx={{
            "& .MuiDrawer-paper": {
              width: { xs: window.innerWidth, md: 400 },
              borderRadius: "15px",
              position: "fixed",
              left: { xs: 0, md: window.innerWidth - 400 },
              zIndex: 4000,
              p: 1,
            },
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
          variant="persistent"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: 1,
              padding: 1,
            }}
          >
            <Box>
              <Typography variant={"h6"}>{chatOpen.title!}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box>
                <ActionButtons onClick={handleCloseIndividualChat} label="Close" icon={<Close />} />
              </Box>
            </Box>
          </Box>
          <ChatComponent open={chatOpen.open} chatId={chatOpen.id!} teamChat={false} techChat={chatOpen.tech} />
        </Drawer>
      </Box>
    </>
  );
};

export default ChatMenu;
