import React, { useState } from "react";
import { Grid } from "@mui/material";
import Modal from "../base/Modal";
import ActionButtons from "../base/ActionButtons";
import AddAnnouncement from "./AddAnnouncement";
import { useDispatch } from "../../store";
import { addAnnouncement } from "../../slices/announcement";
import { CampaignRounded } from "@mui/icons-material";
import Announcement from "../../types/announcement";

interface Props {
  announcementModal: boolean;
  setAnnouncementModal: (type: string) => void;
}

const CaseMenu: React.FC<Props> = (props) => {
  const { announcementModal, setAnnouncementModal } = props;

  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOpenModal = () => setAnnouncementModal("announcement");
  const handleCloseModal = () => setAnnouncementModal("none");

  const handleAddAnnouncement = async (model: Announcement) => {
    setIsSubmitting(true);
    await dispatch(addAnnouncement(model));
    handleCloseModal();
    setIsSubmitting(false);
  };

  return (
    <React.Fragment>
      <Modal open={announcementModal} handleClose={handleCloseModal} title="Add Announcement">
        <AddAnnouncement handleClose={handleCloseModal} isSubmitting={isSubmitting} onSubmit={handleAddAnnouncement} />
      </Modal>
      <Grid container py={3}>
        <Grid item>
          <ActionButtons label="Add Announcement" icon={<CampaignRounded />} onClick={handleOpenModal} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CaseMenu;
