import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { CreateNewFolderRounded } from "@mui/icons-material";
import Modal from "../base/Modal";
import { useDispatch, useSelector } from "../../store";
import { fetchHospitals } from "../../slices/hospital";
import { fetchOnlyRads } from "../../slices/users";
import AddEditCase from "../views/AddEditCase";
import { clearResults } from "../../slices/cases";
import ActionButtons from "../base/ActionButtons";
import getUserRoleId from "../../helpers/getUserRoleId";
import Hospital from "../../types/hospital";

interface Props {
  caseModal: boolean;
  setCaseModal: (type: string) => void;
  hospital: Hospital;
}

const CaseMenu: React.FC<Props> = (props) => {
  const { hospital } = props;
  const {
    // Case modal in parent in order to pass case ID
    caseModal,
    setCaseModal,
  } = props;
  const dispatch = useDispatch();

  const { loggedInUser } = useSelector((store) => store.auth);
  const { kiosk } = useSelector((store) => store.kiosk);

  const handleOpenModal = () => setCaseModal("case");

  const handleCloseModal = () => {
    setCaseModal("none");
  };

  useEffect(() => {
    dispatch(fetchHospitals());
    dispatch(fetchOnlyRads());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearResults());
  }, [dispatch, caseModal]);

  return (
    <React.Fragment>
      <Modal open={caseModal} handleClose={handleCloseModal} title="Add Case">
        <AddEditCase
          kiosk={kiosk}
          // key={caseModal.id}
          // id={caseModal.id}
          handleCloseModal={handleCloseModal}
          hospital={hospital!}
        />
      </Modal>
      <Grid container py={3}>
        <Grid item>
          <ActionButtons
            label="Add Case"
            icon={<CreateNewFolderRounded />}
            disabled={!hospital?.isOpen && (getUserRoleId(loggedInUser) === 3 || kiosk)}
            onClick={handleOpenModal}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CaseMenu;
