import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Grid, Paper, Divider, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "../../store";
import { searchCases } from "../../slices/cases";
import CaseCard from "../views/CaseCard";
import { Download } from "@mui/icons-material";
import Option from "../../types/option";
import theme from "../../theme";
import SearchParams from "../../types/searchParams";
import SearchBar from "../base/SearchBar";
import { debounce } from "lodash";
import FilterDropdown from "../base/FilterDropdown";
import StyledTable from "../base/StyledTable";
import { getReport } from "../../slices/report";
import Report from "../../types/report";

const CompletedCases: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { caseSearch, caseStatus } = useSelector((store) => store.cases);
  const { reportStatus } = useSelector((store) => store.reports);

  const [filterForm, setFilterForm] = useState<SearchParams>({
    pageNumber: 1,
    pageSize: 10,
    filters: ["active:3"],
    orderDirection: "Descending",
  });

  const [values] = useState<Report>();

  useEffect(() => {
    dispatch(
      searchCases({
        pageNumber: 1,
        pageSize: 10,
        filters: ["active:3"],
        orderDirection: "Descending",
      })
    );
  }, [dispatch]);

  const handleSearchClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(searchCases(filterForm));
  };

  const updateSearch = debounce((query: string) => {
    setFilterForm({ ...filterForm, pageNumber: 1, pageSize: 10 });
    dispatch(searchCases({ ...filterForm, pageNumber: 1, pageSize: 10, query: query }));
  }, 250);

  const handleFiltering = (e: Option, filterName: string) => {
    let filters = filterForm?.filters;
    let otherFilters = filters?.filter((f) => f.split(":")[0] !== filterName);

    if (e) {
      let newFilters: string[] = [`${filterName}:${e.id}`];
      let newList = otherFilters ? newFilters.concat(otherFilters) : newFilters;
      setFilterForm({ ...filterForm, pageNumber: 1, pageSize: 10, filters: newList });
      dispatch(searchCases({ ...filterForm, pageNumber: 1, pageSize: 10, filters: newList }));
    } else {
      setFilterForm({ ...filterForm, pageNumber: 1, pageSize: 10, filters: otherFilters });
      dispatch(searchCases({ ...filterForm, pageNumber: 1, pageSize: 10, filters: otherFilters }));
    }
  };

  const changePage = (pageNumber: number, pageSize: number) => {
    dispatch(
      searchCases({
        ...filterForm,
        pageNumber: pageNumber,
        pageSize: pageSize,
      })
    ).then(() => {
      setFilterForm({ ...filterForm, pageNumber: pageNumber, pageSize: pageSize });
    });
  };

  const filterOptions = [
    {
      id: 1,
      value: "All",
    },
    {
      id: 2,
      value: "Active",
    },
    {
      id: 3,
      value: "Completed",
    },
  ];

  const getFilter = () => {
    let filters = filterForm?.filters;
    switch (filters && filters[0]!) {
      case "active:1":
        return 1;
      case "active:2":
        return 2;
      case "active:3":
        return 3;
      default:
        return 1;
    }
  };

  const rows =
    caseSearch?.results.map((c) => {
      return <CaseCard case={c} />;
    }) || [];

  const handleDownload = () => {
    dispatch(getReport({ reportUrl: `reports/cases`, model: { ...values, statusId: getFilter() } }));
  };

  return (
    <Box m={2} py={3}>
      <Paper>
        <Grid container padding="15px">
          <Grid item xs={12}>
            <Typography variant={"h6"}>Completed Cases</Typography>
          </Grid>
        </Grid>
        <Divider />
        <form style={{ width: "100%", display: "flex" }} onSubmit={handleSearchClick}>
          <Grid container justifyContent="center" padding="15px" spacing={2}>
            <Grid item xs={12} md={4}>
              <SearchBar value="Search" variant="body2" handleChange={updateSearch} />
            </Grid>
            <Grid item xs={12} md={4}>
              <FilterDropdown
                label="Filter by State"
                sortOptions={filterOptions}
                handleChange={(e: Option) => {
                  handleFiltering(e, "active");
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                color="info"
                variant="contained"
                onClick={() => {
                  handleDownload();
                }}
                startIcon={
                  reportStatus === "loading" ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <Download fontSize="small" />
                  )
                }
                style={{
                  marginTop: 5,
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                }}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid container padding="5px">
          <Grid item xs={12}>
            {caseStatus === "loading" ? (
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", padding: 3 }}>
                <CircularProgress color="inherit" size={50} />
              </Box>
            ) : (
              <Box>
                <StyledTable
                  rows={rows}
                  paging={true}
                  page={filterForm.pageNumber}
                  rowsPerPage={filterForm.pageSize}
                  totalRows={caseSearch?.totalResults}
                  changePage={changePage}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default CompletedCases;
