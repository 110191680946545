import React, { useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Grid,
  Button,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "../../store";
import * as Yup from "yup";
import Card from "../base/Card";
import { Field, Form, Formik } from "formik";
import { Download } from "@mui/icons-material";
import Option from "../../types/option";
import theme from "../../theme";
import { getReport } from "../../slices/report";
import { Inputs } from "../forms";
import { fetchHospitals } from "../../slices/hospital";
import Report from "../../types/report";
import HospitalSummary from "../../types/hospitalSummary";
import { fetchUrgencies } from "../../slices/cases";
import { fetchOnlyRads, fetchUsers } from "../../slices/users";

type DataCategories = "casesData" | "qaCasesData" | "awayState" | "radiologistSessions" | "userSessions";

const dataCategories: Option<DataCategories>[] = [
  {
    id: 0,
    value: "casesData",
  },
  {
    id: 1,
    value: "qaCasesData",
  },
  {
    id: 2,
    value: "awayState",
  },
  {
    id: 3,
    value: "radiologistSessions",
  },
  {
    id: 4,
    value: "userSessions",
  },
];

const AddEditUser: React.FC = () => {
  const dispatch = useDispatch();
  const { hospitals } = useSelector((store) => store.hospital);
  const { reportStatus } = useSelector((store) => store.reports);
  const { urgencies } = useSelector((store) => store.cases);
  const { radUsers, users } = useSelector((store) => store.users);

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(fetchHospitals());
    dispatch(fetchUrgencies());
    dispatch(fetchOnlyRads());
    dispatch(fetchUsers({ includeTechs: false, includeAdmins: true }));
  }, [dispatch]);

  const handleSubmit = (e: any) => {
    // console.log("submitted", e);
  };

  const getLink = (values: any) => {
    switch (values.dataCategory) {
      case 0:
        return `reports/cases`;
      case 1:
        return `reports/qa`;
      case 2:
        return `reports/away`;
      case 3:
        return `reports/rads`;
      case 4:
        return `reports/sessions`;
      default:
        return `reports/cases`;
    }
  };

  const getLabel = (value: DataCategories): string => {
    switch (value) {
      case "casesData":
        return "Case Data";
      case "qaCasesData":
        return "QA Case Data";
      case "awayState":
        return "Away State Data";
      case "radiologistSessions":
        return "Radiologist Sessions";
      case "userSessions":
        return "User Sessions";
      default:
        return "";
    }
  };

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().required("Please select a start date"),
    endDate: Yup.date().required("Please select a end date"),
    dataCategory: Yup.string().required(""),
    reportType: Yup.string().required(""),
    hospital: Yup.array().nullable(),
    urgency: Yup.array().nullable(),
    userId: Yup.array().nullable(),
  });

  const initialValue: {
    startDate: Date;
    endDate: Date;
    dataCategory: number;
    reportType: number;
    hospital: HospitalSummary[];
    urgency: Option[];
    userId: number;
  } = {
    startDate: new Date(),
    endDate: new Date(),
    dataCategory: 0,
    reportType: 0,
    hospital: [],
    urgency: [],
    userId: 0,
  };

  const handleDownload = (values: Report) => {
    const reportUrl = getLink(values);
    dispatch(getReport({ reportUrl: reportUrl, model: values }));
  };

  return (
    <Box mt={3} sx={{ p: "20px" }}>
      <Formik initialValues={initialValue} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, setFieldValue, submitForm }) => (
          <Form noValidate>
            <Card>
              <Grid container display="flex" flexDirection="row" sx={{ p: "10px" }}>
                <Typography color="textPrimary" variant="h5">
                  Standard Reports
                </Typography>
              </Grid>
              <Grid container display="flex" flexDirection="row" justifyContent="space-evenly" sx={{ p: "20px" }}>
                <Grid item xs={12} sm={4} display="flex" flexDirection={sm ? "row" : "column"}>
                  <Grid item xs={12} display="flex" flexDirection="row">
                    <FormControl>
                      <Typography>Choose a data category:</Typography>
                      <RadioGroup>
                        {dataCategories.map((category) => (
                          <FormControlLabel
                            key={category.id}
                            label={getLabel(category.value)}
                            name={category.value}
                            control={
                              <Radio
                                color="secondary"
                                checked={values.dataCategory === category.id}
                                onChange={() => {
                                  setFieldValue("dataCategory", category.id);
                                  setFieldValue("userId", undefined);
                                }}
                              />
                            }
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={8} display="flex" flexDirection={"column"} sx={{ marginTop: sm ? "20px" : "" }}>
                  <Grid item xs={12} display="flex" flexDirection="row" flexBasis="0" marginBottom="15px">
                    <Typography>Choose filters:</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection={sm ? "column" : "row"}
                    gap={2}
                    flexBasis="0"
                    marginBottom="15px"
                  >
                    <Grid item xs={12} sm={6}>
                      <Field name="startDate" label="Start Date" required fullWidth component={Inputs.Datepicker} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="endDate" label="End Date" required fullWidth component={Inputs.Datepicker} />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} display="flex" flexDirection={sm ? "column" : "row"} gap={2} flexBasis="0">
                    {(values.dataCategory === 0 || values.dataCategory === 1) && (
                      <Grid item xs={12} sm={6}>
                        <Field
                          multiple={true}
                          name="hospital"
                          label="Hospital"
                          component={Inputs.Dropdown}
                          options={hospitals || []}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6}>
                      {values.dataCategory === 0 && (
                        <Field
                          multiple={true}
                          name="urgency"
                          label="Urgency"
                          component={Inputs.Dropdown}
                          options={urgencies || []}
                        />
                      )}
                      {values.dataCategory === 3 && (
                        <Field
                          name="userId"
                          label="User"
                          component={Inputs.Dropdown}
                          options={radUsers || []}
                          handleChange={(e: any, value: any) => {
                            setFieldValue("userId", value?.id!);
                          }}
                        />
                      )}
                      {values.dataCategory === 4 && (
                        <Field
                          name="userId"
                          label="User"
                          component={Inputs.Dropdown}
                          options={users || []}
                          handleChange={(e: any, value: any) => {
                            setFieldValue("userId", value?.id!);
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container display="flex" flexDirection="row" justifyContent="space-evenly" sx={{ p: "20px" }}>
                <Grid item xs={12}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      handleDownload(values);
                    }}
                    startIcon={
                      reportStatus === "loading" ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <Download fontSize="small" />
                      )
                    }
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                    }}
                  >
                    Generate Report
                  </Button>
                </Grid>
              </Grid>
              {/* <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography color="textPrimary" variant="h5">
                      Standard Reports
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Choose a start and end date:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid container spacing={3}>
                        <Grid item>
                          <DesktopDatePicker
                            label="Start Date"
                            inputFormat="MM/dd/yyyy"
                            value={values.startDate}
                            onChange={(e) => setFieldValue("startDate", e)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Grid>
                        <Grid item>
                          <DesktopDatePicker
                            label="End Date"
                            inputFormat="MM/dd/yyyy"
                            value={values.endDate}
                            onChange={(e) => setFieldValue("endDate", e)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Grid>
                      </Grid>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <FormLabel>Choose a data category:</FormLabel>
                      <RadioGroup>
                        {dataCategories.map((category) => (
                          <FormControlLabel
                            key={category.id}
                            label={getLabel(category.value)}
                            name={category.value}
                            control={
                              <Radio
                                color="info"
                                checked={values.dataCategory === category.id}
                                onChange={() => setFieldValue("dataCategory", category.id)}
                              />
                            }
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      multiple={true}
                      name="hospital"
                      label="Hospital"
                      component={Inputs.Dropdown}
                      options={hospitals || []}
                      handleChange={handleSelectHospitals}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      multiple={true}
                      name="urgency"
                      label="Urgency"
                      component={Inputs.Dropdown}
                      options={urgenciesOptions}
                      handleChange={handleSelectUrgencies}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="info"
                      variant="contained"
                      onClick={() => {
                        handleDownload(getLink(values));
                      }}
                      startIcon={<Download />}
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                      }}
                    >
                      Generate Report
                    </Button>
                  </Grid>
                </Grid> */}
            </Card>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddEditUser;
