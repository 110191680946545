import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Autocomplete,
  Grid,
  Fade,
  Modal,
  Paper,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "../../store";
import { addTeamUser, fetchTeamChat, removeTeamUser } from "../../slices/teamchats";
import { fetchUsers } from "../../slices/users";
import theme from "../../theme";
import StyledTable from "../base/StyledTable";

interface DeleteModal {
  id: number | null;
  visible: boolean;
}

interface NewUser {
  id: number | null;
  label: string | null;
}

const TeamChatsTable: React.FC = () => {
  const dispatch = useDispatch();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { teamChats } = useSelector((store) => store.teamChats);
  const { users } = useSelector((store) => store.users);

  const [isWorking, setIsWorking] = useState(false);
  const [deleteModal, setDeleteModal] = useState<DeleteModal>({
    visible: false,
    id: null,
  });
  const [newUser, setNewUser] = useState<NewUser>({
    id: null,
    label: null,
  });

  const handleOpenDeleteModal = (id: number) =>
    setDeleteModal({
      visible: true,
      id: id,
    });

  const handleCloseDeleteModal = () => {
    setDeleteModal((prevState) => {
      return {
        ...prevState,
        visible: false,
      };
    });
    setTimeout(() => {
      setDeleteModal((prevState) => {
        return {
          ...prevState,
          id: null,
        };
      });
    }, 250);
  };

  const handleDeleteUser = async () => {
    handleCloseDeleteModal();
    await dispatch(removeTeamUser(deleteModal?.id || 0));
    dispatch(fetchTeamChat());
  };

  const handleAddUser = async () => {
    setIsWorking(true);
    await dispatch(addTeamUser(newUser?.id || 0));
    dispatch(fetchTeamChat());
    setIsWorking(false);
    setNewUser({
      id: null,
      label: null,
    });
  };

  useEffect(() => {
    dispatch(fetchTeamChat());
    dispatch(fetchUsers({ includeTechs: false, includeAdmins: true }));
  }, [dispatch]);

  const filteredUsers = users
    ?.filter((user) => !teamChats?.find((u) => u.id === user.id))
    .map((user) => {
      return { label: user.value, value: user?.id };
    });

  const headers = ["User", "Actions"];
  const rows =
    teamChats?.map((user) => {
      return {
        user: (
          <Box>
            <Typography color="inherit" variant="subtitle1">
              {user.value}
            </Typography>
          </Box>
        ),
        actions: (
          <Tooltip title={<Typography variant="button">Remove</Typography>} placement="top">
            <IconButton onClick={() => handleOpenDeleteModal(user?.id || 0)} color="inherit">
              <Delete />
            </IconButton>
          </Tooltip>
        ),
      };
    }) || [];

  return (
    <>
      <Modal open={deleteModal.visible} closeAfterTransition={true} onClose={handleCloseDeleteModal}>
        <Fade in={deleteModal.visible}>
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: sm ? 350 : 650,
            }}
          >
            <Paper style={{ overflow: "hidden" }}>
              <Grid margin={2}>
                <Typography>
                  Are you sure you would like to remove "
                  {(() => {
                    const foundUser = teamChats?.find((user) => user?.id === deleteModal?.id);
                    return `${foundUser?.value}`;
                  })()}
                  " from the Reading Room?
                </Typography>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                  <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="secondary"
                        fullWidth
                        size="large"
                        type="button"
                        variant="contained"
                        onClick={handleCloseDeleteModal}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleDeleteUser}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>

      <Paper>
        <Grid container padding="15px">
          <Grid item xs={11} padding={1}>
            <Typography variant={"h6"}>Edit Reading Room</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={3} alignItems="center" py={3} px={3}>
          <Grid item md={3} xs={8}>
            <Autocomplete
              fullWidth
              disablePortal
              value={{ label: newUser.label || "", value: newUser.id || 0 }}
              onChange={(label, value) => {
                if (!value)
                  return setNewUser({
                    id: null,
                    label: null,
                  });
                setNewUser({
                  id: value?.value,
                  label: (() => {
                    const foundUser = users?.find((user) => user?.id === value?.value);
                    return foundUser?.value!;
                  })(),
                });
              }}
              options={[...filteredUsers]}
              renderInput={(params) => <TextField label="Users" {...params} />}
            />
          </Grid>
          <Grid md={undefined} xs={3} item>
            <Button
              disabled={!newUser.id || isWorking}
              onClick={handleAddUser}
              color="primary"
              startIcon={<Add fontSize="small" />}
              variant="contained"
            >
              Add User
            </Button>
          </Grid>
        </Grid>
        <Box>
          <StyledTable headers={headers} rows={rows} useObjects />
        </Box>
      </Paper>
    </>
  );
};

export default TeamChatsTable;
