import React, { useState } from "react";
import { Box, Button, Grid, InputAdornment, TextField } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";
import * as Yup from "yup";
import Announcement from "../../types/announcement";
import ActionButtons from "../base/ActionButtons";
import { InsertEmoticon } from "@mui/icons-material";
import Emojis from "../base/Emojis";

interface Props {
  onSubmit: (model: Announcement) => void;
  isSubmitting: boolean;
  handleClose: () => void;
}

const AddAnnouncement: React.FC<Props> = (props) => {
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [announcement, setAnnouncement] = useState<Announcement>({
    id: 0,
    title: "",
    body: "",
    sendEmail: false,
    sendText: false,
  });

  const togglePicker = () => {
    setEmojiOpen(!emojiOpen);
  };

  const onEmojiClick = (emojiObject: any) => {
    setAnnouncement({
      ...announcement,
      body: announcement.body + emojiObject.emoji,
    });
  };

  const handleSave = (values: Announcement) => {
    if (announcement && announcement.body && announcement.body.length > 0) {
      props.onSubmit({
        title: values.title,
        body: announcement.body,
        sendEmail: values.sendEmail,
        sendText: values.sendText,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string(),
  });

  return (
    <React.Fragment>
      <Formik
        initialValues={announcement}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          handleSave(values);
        }}
      >
        {() => (
          <Form noValidate>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Field name="title" label="Title" component={Inputs.Text} />
              </Grid>
              <Emojis announcement={true} onClick={onEmojiClick} emojiOpen={emojiOpen} setEmojiOpen={setEmojiOpen} />
              <Grid item xs={12}>
                <TextField
                  multiline
                  required
                  inputProps={{
                    spellCheck: "true",
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end">
                          <ActionButtons onClick={togglePicker} icon={<InsertEmoticon />} label="Add Emoji" />
                        </InputAdornment>
                      </>
                    ),
                  }}
                  value={announcement.body}
                  onChange={(e) => {
                    setAnnouncement({
                      ...announcement,
                      body: e.target.value,
                    });
                  }}
                  label="Body"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="sendEmail" label="Send Urgent Email" required component={Inputs.Checkbox} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="sendText" label="Send Urgent Text" required component={Inputs.Checkbox} />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ mt: 2 }}>
                  <Button
                    color="secondary"
                    fullWidth
                    size="large"
                    type="button"
                    variant="contained"
                    onClick={props.handleClose}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Inputs.Submit text={"Add"} isSubmitting={props.isSubmitting} />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default AddAnnouncement;
