import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, Tooltip, Modal, Fade, Paper, Grid, Button, useMediaQuery } from "@mui/material";
import UserCard from "./UserCard";
import HospitalType from "../../types/hospital";
import User from "../../types/user";
import Conversation from "../../types/conversation";
import getUserRoleId from "../../helpers/getUserRoleId";
import { useDispatch, useSelector } from "../../store";
import { closeHospital, openHospital } from "../../slices/hospital";
import theme from "../../theme";

interface Props {
  hospital?: HospitalType;
  users: User[];
  collapsed: boolean;
  showHospital?: boolean;
  loggedInUser: User;
  conversations?: Conversation[];
  techConversations?: Conversation[];
  techChat?: boolean;
}

const HospitalCard: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { hospital, users, collapsed, showHospital, loggedInUser, conversations, techConversations, techChat } = props;
  const { userScheduleStatus } = useSelector((store) => store.schedule);

  const [working, setWorking] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const isOpen = !!hospital?.isOpen;

  useEffect(() => {
    if (userScheduleStatus === "idle") {
      setWorking(false);
    }
  }, [userScheduleStatus]);

  const handleCloseHospital = async () => {
    if (!hospital?.id) return;
    setOpenModal(false);
    setWorking(true);
    await dispatch(closeHospital(hospital.id));
  };

  const handleOpenHospital = async () => {
    if (!hospital?.id) return;
    setOpenModal(false);
    setWorking(true);
    await dispatch(openHospital(hospital.id));
  };

  const handleCloseConfirmationModal = () => {
    setOpenModal(false);
  };

  let sortedUsers = users.sort((a, b) => {
    if (a.id === 0) return 1;
    if (b.id === 0) return -1;
    return 0;
  });

  return (
    <>
      <Modal open={openModal} closeAfterTransition={true} onClose={handleCloseConfirmationModal}>
        <Fade in={openModal}>
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: sm ? 350 : 500,
            }}
          >
            <Paper style={{ overflow: "hidden" }}>
              <Grid margin={2} textAlign="center">
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                  Are you sure you would like to {!!hospital?.isOpen ? "close" : "open"} {hospital?.name}?
                </Typography>
                <Typography>Users will {!!hospital?.isOpen && "not "}be able to submit new cases.</Typography>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                  <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="secondary"
                        fullWidth
                        size="large"
                        type="button"
                        variant="contained"
                        onClick={handleCloseConfirmationModal}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={!!hospital?.isOpen ? handleCloseHospital : handleOpenHospital}
                      >
                        Confirm
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>
      <Box
        sx={{ display: "flex", flexDirection: "row", gap: 1, overflowY: "auto", overflowX: "hidden", height: "100%" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, gap: 1 }}>
          {sortedUsers?.map((user) => {
            const unreadIndividual = conversations?.find((f) => f.unread! > 0 && !f.isTech && f.id === user.id);
            const unreadTech = techConversations?.find(
              (f) => f.unread! > 0 && !!f.isTech && f.hospitalId === user.hospital?.id && !!user.techChat
            );
            return (
              <UserCard
                newMessages={!!user.techChat ? unreadTech : unreadIndividual}
                key={user.id}
                user={user}
                collapsed={collapsed.toString()}
              />
            );
          })}
        </Box>
        {props.techChat ? (
          <></>
        ) : showHospital && !isOpen && hospital?.hospitalLocation && hospital?.shortName?.length! > 0 ? (
          <Tooltip
            title={hospital?.closedMessage || "RADS is no longer accepting submissions for this location."}
            placement="top"
          >
            <Box
              sx={{
                backgroundColor: theme.palette.text.secondary,
                textTransform: "uppercase",
                borderRadius: "15px;",
                width: "55px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="overline"
                sx={{ fontWeight: 700, fontSize: ".7rem", lineHeight: "20px", marginTop: "2px" }}
              >
                {hospital?.shortName || "NA"}
              </Typography>
              {!!hospital && getUserRoleId(loggedInUser) !== 3 && hospital.shortName.toLowerCase() !== "ir float" && (
                <React.Fragment>
                  <IconButton
                    aria-label="Example"
                    sx={{
                      backgroundColor: theme.palette.error.main,
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      marginTop: "5px",
                    }}
                    disabled={working}
                    onClick={() => setOpenModal(true)}
                  >
                    <Typography variant="overline" style={{ fontSize: "0.6rem", fontWeight: "bold" }}>
                      Off
                    </Typography>
                  </IconButton>
                </React.Fragment>
              )}
            </Box>
          </Tooltip>
        ) : (
          <Box
            sx={{
              backgroundColor:
                hospital?.shortName?.length! > 0 ? hospital?.badgeColor || theme.palette.background.paper : "",
              textTransform: "uppercase",
              borderRadius: "15px;",
              width: "55px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {!hospital?.hospitalLocation &&
            hospital?.shortName.split(" ")?.filter((h) => h.length! > 5)?.length! > 0 ? (
              <Typography
                variant="overline"
                sx={{ fontWeight: 700, fontSize: ".7rem", lineHeight: "20px", marginTop: "2px" }}
              >
                Other
              </Typography>
            ) : !hospital?.hospitalLocation && hospital?.shortName?.split(" ")?.length! > 1 ? (
              <>
                {hospital?.shortName?.split(" ").map((name) => {
                  return (
                    <Typography
                      key={name}
                      variant="overline"
                      sx={{ fontWeight: 700, fontSize: ".7rem", lineHeight: "20px", marginTop: "2px" }}
                    >
                      {name}
                    </Typography>
                  );
                })}
              </>
            ) : (
              <Typography
                variant="overline"
                sx={{ fontWeight: 700, fontSize: ".7rem", lineHeight: "20px", marginTop: "2px" }}
              >
                {hospital?.shortName || ""}
              </Typography>
            )}

            {!!hospital &&
              getUserRoleId(loggedInUser) !== 3 &&
              hospital?.hospitalLocation &&
              hospital.shortName.toLowerCase() !== "ir float" && (
                <React.Fragment>
                  <IconButton
                    aria-label="Example"
                    sx={{
                      backgroundColor: theme.palette.success.main,
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      marginTop: "5px",
                    }}
                    disabled={working}
                    onClick={() => setOpenModal(true)}
                  >
                    <Typography variant="overline" style={{ fontSize: "0.6rem", fontWeight: "bold" }}>
                      On
                    </Typography>
                  </IconButton>
                </React.Fragment>
              )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default HospitalCard;
