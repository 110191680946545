import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Fab,
  Fade,
  FormControlLabel,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Message from "../../types/message";
import { useDispatch, useSelector } from "../../store";
import { InsertEmoticon, Send, Textsms } from "@mui/icons-material";
import ActionButtons from "../base/ActionButtons";
import UserSimple from "../../types/userSimple";
import Moment from "react-moment";
import Avatar from "../base/Avatar";
import Emojis from "../base/Emojis";
import { saveChatDraft, sendAsText } from "../../slices/chat";
import SnackBarToast from "../base/SnackbarToast";
import S3Avatar from "../base/S3Avatar";
import theme from "../../theme";

interface Props {
  chatId: number;
  open: boolean;
  conversation: Message[];
  toUsers: UserSimple[];
  read: (id: number) => void;
  getConversation: () => void;
  send: (message: Message) => Promise<any>;
  // techChat: boolean;
}

const ChatView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { chatId, open, conversation, toUsers, read, getConversation, send } = props;
  const { loggedInUser } = useSelector((state) => state.auth);
  const { chatDraft, textStatus, chatOpen, status } = useSelector((state) => state.chat);
  const { techChatHospitalId } = useSelector((state) => state.techChats);

  const [emojiOpen, setEmojiOpen] = useState(false);
  const [model, setModel] = useState<Message[]>(conversation);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [messageId, setMessageId] = useState<number | undefined>();
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState<Message>({
    id: 0,
    time: new Date(),
    toUsers: toUsers,
    fromUser: {} as UserSimple,
    text: chatId !== 0 && !chatOpen?.tech && chatId === chatDraft?.id ? chatDraft.message : "",
    sendAsText: false,
    techChat: chatOpen.tech,
    hospitalId: !!chatOpen.tech ? techChatHospitalId : undefined,
  });

  useEffect(() => {
    setMessage({
      ...message,
      techChat: chatOpen.tech,
      hospitalId: !!chatOpen.tech ? techChatHospitalId : undefined,
      text: "",
    });
  }, [chatOpen.id, chatOpen.tech, techChatHospitalId]);

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const togglePicker = () => {
    setEmojiOpen(!emojiOpen);
  };

  const onEmojiClick = (emojiObject: any) => {
    setMessage({
      ...message,
      text: message.text + emojiObject.emoji,
      toUsers: message.toUsers,
    });
  };

  const [messageSending, setMessageSending] = useState(false);

  const sendMessage = async () => {
    if (message && message.text?.length > 0 && message.toUsers?.length > 0) {
      setMessageSending(true);
      await send(message)
        .then(() => {
          if (message.sendAsText) {
            setSuccess(true);
          }
          if (chatId !== 0) {
            dispatch(saveChatDraft(undefined));
          }
          setMessage({
            ...message,
            text: "",
            toUsers: message.toUsers,
            sendAsText: false,
          });
          setMessageSending(false);
        })
        .catch(() => setMessageSending(false));
    }
  };

  const handleUpdateMessage = (text: string) => {
    setMessage({
      ...message,
      text: text,
    });
    if (chatId !== 0 && !chatOpen?.tech) {
      dispatch(saveChatDraft({ id: chatId, message: text }));
    }
  };

  const handleTextCheckbox = (value: boolean) => {
    setMessage({
      ...message,
      sendAsText: value,
    });
  };

  const sendAsTextMessage = async () => {
    await dispatch(sendAsText(messageId!));
    handleCloseModal();
    setSuccess(true);
  };

  const handleCloseModal = () => {
    setConfirmationModal(false);
    setMessageId(undefined);
  };

  const enterPressed = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const key = event.key;
    if (key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" })!;
    }
  };

  useEffect(scrollToBottom, [message, model]);

  useEffect(() => {
    if (!open) return;
    if (!chatId) return;
  });

  useEffect(() => {
    setMessage({
      ...message,
      toUsers: toUsers,
    });
  }, [toUsers]);

  useEffect(() => {
    setModel(conversation);
    if (conversation && conversation.length > 0) {
      let newMessages = conversation.filter((c) => c.fromUser.id !== loggedInUser?.id && !c.read);

      if (newMessages && newMessages.length > 0) {
        newMessages.forEach((chat) => {
          read(chat.id);
        });

        getConversation();
      }
    }
  }, [conversation, loggedInUser]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <List style={{ height: "20vh", overflowY: "auto" }}>
          {model &&
            model?.map((chat: Message) => (
              <Fragment key={chat.id}>
                <ListItem
                  sx={
                    chat.fromUser.id === loggedInUser?.id ? { pb: 0, textAlign: "right" } : { pb: 0, textAlign: "left" }
                  }
                >
                  {chat.fromUser.id !== loggedInUser?.id ? (
                    <ListItemIcon sx={{ mr: 1, ml: 0 }}>
                      <Avatar
                        alt={`${chat.fromUser?.value}`}
                        sx={{
                          borderWidth: "2px",
                          borderColor: "#fff",
                          borderStyle: "solid",
                          mx: "4px",
                          alignItems: "left",
                          height: chatId === 0 ? "35px" : "55px",
                          width: chatId === 0 ? "35px" : "55px",
                        }}
                      >
                        <S3Avatar
                          firstName={chat.fromUser?.value}
                          lastName={""}
                          imageId={chat.fromUser.id}
                          height={chatId === 0 ? "35px" : "55px"}
                        />
                      </Avatar>
                    </ListItemIcon>
                  ) : (
                    chatId > 0 &&
                    !chat.techChat &&
                    chat.toUsers &&
                    chat.toUsers[0]?.receiveTexts && (
                      <ListItemIcon>
                        <ActionButtons
                          disabled={textStatus === "loading"}
                          onClick={() => {
                            setMessageId(chat.id);
                            setConfirmationModal(true);
                            // sendAsTextMessage(chat.id);
                          }}
                          icon={<Textsms fontSize="small" />}
                          label={"Send as Text Message"}
                        />
                      </ListItemIcon>
                    )
                  )}
                  <ListItemText primary={chat.text} primaryTypographyProps={{ fontSize: "20px" }} />
                  {chat.fromUser.id === loggedInUser?.id && (
                    <>
                      <ListItemIcon sx={{ mr: 0, ml: 1 }}>
                        <Avatar
                          alt={`${chat.fromUser?.value}`}
                          sx={{
                            width: chatId === 0 ? 35 : 55,
                            height: chatId === 0 ? 35 : 55,
                            borderWidth: "2px",
                            borderColor: "#fff",
                            borderStyle: "solid",
                            mx: "4px",
                            alignItems: "left",
                          }}
                        >
                          <S3Avatar
                            firstName={chat.fromUser?.value}
                            lastName={""}
                            imageId={chat.fromUser.id}
                            height={chatId === 0 ? "35px" : "55px"}
                          />
                        </Avatar>
                      </ListItemIcon>
                    </>
                  )}
                </ListItem>
                <ListItem
                  sx={
                    chat.fromUser.id === loggedInUser?.id ? { pt: 0, textAlign: "right" } : { pt: 0, textAlign: "left" }
                  }
                >
                  <ListItemText
                    secondary={
                      <span>
                        {chat.fromUser?.value} <Moment format="MM/DD/YY HH:mm">{chat.time}</Moment>
                      </span>
                    }
                    secondaryTypographyProps={chatId === 0 ? { fontSize: "12px" } : {}}
                  />
                </ListItem>
              </Fragment>
            ))}
          <div ref={messagesEndRef} />
        </List>
        <Divider />
        <Emojis announcement={false} onClick={onEmojiClick} emojiOpen={emojiOpen} setEmojiOpen={setEmojiOpen} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px",
            marginTop: 1,
          }}
        >
          <Box sx={{ flex: 1, alignSelf: "stretch" }}>
            <TextField
              multiline
              inputProps={{
                spellCheck: "true",
                resize: "both",
                height: "100%",
              }}
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <ActionButtons onClick={togglePicker} icon={<InsertEmoticon />} label="Add Emoji" />
                    </InputAdornment>
                  </>
                ),
                endAdornment:
                  status === "loading" || !!messageSending || message.toUsers?.length < 1 ? (
                    <CircularProgress size="20px" />
                  ) : (
                    <></>
                  ),
              }}
              onKeyPress={(e) => enterPressed(e)}
              value={message?.text || ""}
              onChange={(e) => {
                handleUpdateMessage(e.target.value);
              }}
              id="outlined-basic-email"
              label={`Send Message`}
              fullWidth
            />
          </Box>
          {(chatId < 1 ||
            !message.toUsers ||
            !message.toUsers[0]?.receiveTexts ||
            (!!chatOpen.tech && !!techChatHospitalId)) && (
            <Box sx={{ paddingLeft: 1 }}>
              <Fab
                color="primary"
                size="small"
                onClick={sendMessage}
                disabled={status === "loading" || !!messageSending || message.toUsers?.length < 1}
              >
                <Tooltip title={<Typography variant="button">Send</Typography>}>
                  <Send />
                </Tooltip>
              </Fab>
            </Box>
          )}
        </Box>
        {chatId > 0 && !message.techChat && message.toUsers && message.toUsers[0]?.receiveTexts && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px",
              marginTop: 1,
            }}
          >
            <Box sx={{ paddingLeft: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={message.sendAsText}
                    onChange={(e) => {
                      handleTextCheckbox(e.target.checked);
                    }}
                  />
                }
                label={<Typography variant="caption">Send as Text Message</Typography>}
                labelPlacement="end"
              />
            </Box>
            <Box>
              <Fab
                color="primary"
                size="small"
                onClick={sendMessage}
                disabled={status === "loading" || !!messageSending || message.toUsers?.length < 1}
              >
                <Tooltip title={<Typography variant="button">Send</Typography>}>
                  <Send />
                </Tooltip>
              </Fab>
            </Box>
          </Box>
        )}
      </Grid>
      <SnackBarToast
        message="Text Message Sent Successfully"
        type="success"
        open={success}
        handleClose={() => {
          setSuccess(false);
        }}
      />
      <Modal open={confirmationModal} closeAfterTransition={true} onClose={handleCloseModal}>
        <Fade in={confirmationModal}>
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: sm ? 350 : 650,
            }}
          >
            <Paper style={{ overflow: "hidden" }}>
              <Grid margin={2}>
                <Typography>Are you sure you would like to re-send this chat as a text message?</Typography>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                  <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="secondary"
                        fullWidth
                        size="large"
                        type="button"
                        variant="contained"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={sendAsTextMessage}
                        disabled={textStatus === "loading"}
                      >
                        Send
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>
    </Grid>
  );
};

export default ChatView;
