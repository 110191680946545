import React, { FunctionComponent } from "react";
import {
  Backdrop,
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import theme from "../../../theme";
import PhoneInput from "react-phone-input-2";
import styled from "styled-components";

const Root = styled.div`
  .react-tel-input {
    .special-label {
      font-size: 0.75rem;
      left: 10px;
      top: -9px;
      color: #838c9a;
      background-color: #404750;
    }
    .form-control {
      margin-top: 1px;
      border-radius: 16px;
      border-color: theme.palette.primary.main;
      background: transparent;
    }
    .country-list {
      background-color: #252526;
      color: #34c1d1;
    }
    .country:hover {
      background-color: #1b1b1c;
      color: #e68627;
    }
    .country.highlight {
      background-color: #5a5a5d;
      color: #e68627;
    }
    .country {
      .dial-code {
        color: #ffffff;
      }
    }
  }
`;

interface OwnProps {
  hospitalDepartments: any[];
  setHospitalDepartments: any;
}

type Props = OwnProps;

const HospitalDepartmentsLocations: FunctionComponent<Props> = React.memo((props) => {
  const { hospitalDepartments, setHospitalDepartments } = props;
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [newDepartment, setNewDepartment] = React.useState({
    name: "",
    phoneNumber: "",
  });
  const [muiModal, setMuiModal] = React.useState(false);

  const handleOpenModal = () => setMuiModal(true);

  const handleCloseModal = () => setMuiModal(false);

  const handleAddDepartmentLocation = () => {
    setHospitalDepartments([
      ...hospitalDepartments,
      {
        id: 0,
        name: newDepartment.name,
        phoneNumber: newDepartment.phoneNumber,
      },
    ]);
    setMuiModal(false);
    setTimeout(() => {
      setNewDepartment({
        name: "",
        phoneNumber: "",
      });
    }, 250);
  };

  const handleDeleteDepartmentLocation = (index: number) => {
    let temp = [...hospitalDepartments];
    temp.splice(index, 1);
    setHospitalDepartments([...temp]);
  };

  return (
    <React.Fragment>
      <Modal
        closeAfterTransition={true}
        open={muiModal}
        onClose={handleCloseModal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={muiModal}>
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: sm ? 350 : 650,
            }}
          >
            <Paper style={{ overflow: "hidden" }}>
              <Grid item xs={12} height={75} display="flex" alignItems="center">
                <Grid
                  marginLeft={3}
                  marginRight={3}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Grid item>
                    <Typography color="textPrimary" variant="h6">
                      New Location / Department
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton type="button" onClick={() => handleCloseModal()}>
                      <CloseRounded />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid margin={3}>
                <Grid item xs={12} marginBottom={3}>
                  <Typography>What is the name of the location or department?</Typography>
                </Grid>
                <Grid marginBottom={3} item xs={12}>
                  <TextField
                    fullWidth
                    label="Name *"
                    value={newDepartment.name}
                    onChange={(e) =>
                      setNewDepartment((prevState) => {
                        return { ...prevState, name: e.target.value };
                      })
                    }
                  />
                </Grid>
                <Grid marginBottom={3} item xs={12}>
                  <Root>
                    <PhoneInput
                      specialLabel="Phone Number"
                      countryCodeEditable={false}
                      country={"us"}
                      placeholder=""
                      onlyCountries={["us"]}
                      onChange={(e) =>
                        setNewDepartment((prevState) => {
                          return { ...prevState, phoneNumber: e };
                        })
                      }
                      value={newDepartment.phoneNumber}
                      inputStyle={{
                        width: "100%",
                        borderColor: `${theme.palette.primary.contrastText}50`,
                        color: theme.palette.primary.contrastText,
                        borderRadius: "16px",
                        backgroundColor: "inherit",
                      }}
                    />
                  </Root>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" alignItems="flex-end">
                    <Button onClick={() => handleCloseModal()}>Cancel</Button>
                    <Button onClick={() => handleAddDepartmentLocation()}>Add</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography color="textPrimary" variant="h6">
                  Departments / Locations
                </Typography>
              </Grid>
              <Grid item>
                <Button onClick={handleOpenModal}>Add Location</Button>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {hospitalDepartments?.map((department, index) => (
            <Grid key={index} item xs={12}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={5}>
                  <TextField disabled fullWidth value={department.name} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    disabled
                    fullWidth
                    value={
                      department && department.phoneNumber
                        ? "(" +
                          department.phoneNumber.substring(1, 4) +
                          ")-" +
                          department.phoneNumber.substring(4, 7) +
                          "-" +
                          department.phoneNumber.substring(7, department.phoneNumber.length)
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button onClick={() => handleDeleteDepartmentLocation(index)}>Delete</Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default HospitalDepartmentsLocations;
