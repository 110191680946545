import React, { ReactNode } from "react";
import { Box, Fade, Paper, Modal as MaterialModal, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import ActionButtons from "../base/ActionButtons";
import { Close } from "@mui/icons-material";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import theme from "../../theme";

interface Props {
  children: ReactNode;
  open: boolean;
  handleClose: () => void;
  title: string;
  size?: number;
  showClose?: boolean;
  border?: string;
  background?: string;
  largeTitle?: boolean;
}

const Modal: React.FC<Props> = (props) => {
  const { children, open, handleClose, title, size, showClose, border, background, largeTitle } = props;

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MaterialModal
      disableScrollLock
      disableAutoFocus={true}
      closeAfterTransition={true}
      sx={{ zIndex: 1200 }}
      open={open}
      onClose={handleClose}
    >
      <Fade in={open}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: size ?? (sm ? 350 : 650),
            height: "100%",
            marginTop: 100,
          }}
        >
          <Paper sx={{ border: border ?? "none", backgroundColor: background ?? "none" }}>
            <Grid container padding="15px">
              <Grid item xs={11} padding={1}>
                <Typography variant={largeTitle ? "h5" : "h6"} color={largeTitle ? "#000" : ""}>
                  {title}
                </Typography>
              </Grid>
              {showClose && (
                <Grid item xs={1} paddingTop={0.5}>
                  <ActionButtons onClick={handleClose} label="Close" icon={<Close />} />
                </Grid>
              )}
            </Grid>
            <Divider />
            <Box
              sx={{
                maxHeight: window.innerHeight * 0.7,
              }}
            >
              <SimpleBar style={{ maxHeight: window.innerHeight * 0.7, height: "100%" }}>
                <Box
                  sx={{
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} padding={2}>
                      {children}
                    </Grid>
                  </Grid>
                </Box>
              </SimpleBar>
            </Box>
          </Paper>
        </Box>
      </Fade>
    </MaterialModal>
  );
};

export default Modal;
