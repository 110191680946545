import React, { Dispatch, SetStateAction } from "react";
import { Box, Divider, Grid, Typography, Button, useMediaQuery } from "@mui/material";
import Case from "../../types/case";
import getUserRoleId from "../../helpers/getUserRoleId";
import CaseActionMenu from "./CaseActionMenu";
import theme from "../../theme";
import { useDispatch, useSelector } from "../../store";
import { searchCases, updateCase } from "../../slices/cases";

interface Props {
  case: Case;
  kiosk?: boolean | undefined;
  isAssigned: { toUser: boolean };
  isCompleted: boolean;
  isBlocked: boolean;
  handleOpenReportModal: () => void;
  open: boolean;
  setStrokeModal: Dispatch<SetStateAction<boolean>>;
  buttonClicked: boolean;
  setButtonClicked: Dispatch<SetStateAction<boolean>>;
  completeCase: () => void;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

const CaseActionsView: React.FC<Props> = (props) => {
  const {
    kiosk,
    isAssigned,
    isCompleted,
    isBlocked,
    handleOpenReportModal,
    open,
    setStrokeModal,
    buttonClicked,
    setButtonClicked,
    completeCase,
    isEditing,
    setIsEditing,
  } = props;
  const { loggedInUser } = useSelector((store) => store.auth);
  const { caseStatus } = useSelector((store) => store.cases);
  const dispatch = useDispatch();
  const handleOpenStrokeModal = () => setStrokeModal(true);

  const md = useMediaQuery(theme.breakpoints.down("md"));

  const handleClaim = async () => {
    setIsEditing(true);
    const newCurrentUser: any = loggedInUser;
    await dispatch(
      updateCase({
        ...props.case,
        status: { id: 2, value: "" },
        currentUser: { ...newCurrentUser },
      })
    );
    setButtonClicked(false);
  };

  const handleComplete = async () => {
    if (props.case.urgency?.id === 1) {
      handleOpenStrokeModal();
    } else {
      await completeCase();
      setButtonClicked(false);
    }
  };

  const handleReactivate = async () => {
    setIsEditing(true);
    await dispatch(
      updateCase({
        ...props.case,
        status: { id: 1, value: "" },
        currentUser: undefined,
      })
    );
    await dispatch(
      searchCases({
        pageNumber: 1,
        pageSize: 10,
        filters: ["active:3"],
        orderDirection: "Descending",
      })
    ).then(() => setButtonClicked(false));
  };

  return (
    <Box>
      {!isBlocked ? (
        <React.Fragment>
          <Divider />
          {!isCompleted ? (
            <React.Fragment>
              {getUserRoleId(loggedInUser) !== 3 &&
              props.case.currentUser &&
              props.case.currentUser?.id !== loggedInUser?.id &&
              props.case.currentUser?.id &&
              !open ? (
                <></>
              ) : getUserRoleId(loggedInUser) !== 3 ? ( //if not blocked or complete or a technologist
                <React.Fragment>
                  <Grid container>
                    <Grid item xs={8}>
                      <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left">
                        <CaseActionMenu kiosk={!!kiosk} case={props.case} type="case" />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="right">
                        <Button
                          sx={{ color: "inherit" }}
                          onClick={() => {
                            setButtonClicked(true);
                            !isAssigned.toUser ? handleClaim() : handleComplete();
                          }}
                          disabled={buttonClicked || isEditing || caseStatus === "loading"}
                        >
                          <Box
                            sx={{
                              userSelect: "none",
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: 20,
                              backgroundColor: theme.palette.secondary.main,
                              width: "fit-content",
                              border: "2px solid white",
                            }}
                          >
                            <Typography variant="button" marginX="8px">
                              {!isAssigned.toUser ? "claim" : "complete"}
                            </Typography>
                          </Box>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ) : (
                //if not blocked or complete but are a technologist
                <React.Fragment>
                  <Grid container>
                    <Grid item xs={8}>
                      <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left">
                        <CaseActionMenu kiosk={!!kiosk} case={props.case} type="case" />
                      </Box>
                    </Grid>
                    {!md && (
                      <Grid item xs={4}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="right">
                          <Button
                            sx={{ color: "inherit" }}
                            onClick={() => {
                              handleOpenReportModal();
                            }}
                          >
                            <Box
                              sx={{
                                userSelect: "none",
                                display: "flex",
                                justifyContent: "center",
                                borderRadius: 20,
                                backgroundColor: theme.palette.secondary.main,
                                width: "fit-content",
                                border: "2px solid white",
                              }}
                            >
                              <Typography variant="button" marginX="8px">
                                Download
                              </Typography>
                            </Box>
                          </Button>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            // complete
            <React.Fragment>
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left">
                    <CaseActionMenu completed case={props.case} type="case" />
                  </Box>
                </Grid>
                <Grid item xs={8} sx={{}}>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="right" alignItems="center">
                    {/* <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Download />}
                      sx={{ height: "fit-content", color: "white", padding: "2px 16px" }}
                      onClick={handleOpenReportModal}
                    >
                      Report
                    </Button> */}

                    {!md && (
                      <Button
                        sx={{ color: "inherit" }}
                        onClick={() => {
                          handleOpenReportModal();
                        }}
                      >
                        <Box
                          sx={{
                            userSelect: "none",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: 20,
                            backgroundColor: theme.palette.secondary.main,
                            width: "fit-content",
                            border: "2px solid white",
                          }}
                        >
                          <Typography variant="button" marginX="8px">
                            Download
                          </Typography>
                        </Box>
                      </Button>
                    )}

                    <Button
                      sx={{ color: "inherit" }}
                      onClick={() => {
                        setButtonClicked(true);
                        handleReactivate();
                      }}
                      disabled={buttonClicked || isEditing || caseStatus === "loading"}
                    >
                      <Box
                        sx={{
                          userSelect: "none",
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: 20,
                          backgroundColor: theme.palette.quaternary.main,
                          width: "fit-content",
                          border: "2px solid white",
                        }}
                      >
                        <Typography variant="button" marginX="8px">
                          Reactivate
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        //if blocked and a technologist
        <React.Fragment>
          {getUserRoleId(loggedInUser) === 3 ? (
            <React.Fragment>
              <Divider />
              <Grid container>
                <Grid item xs={8}>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left">
                    <CaseActionMenu case={props.case} type="case" />
                  </Box>
                </Grid>
                {!md && (
                  <Grid item xs={4}>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="right">
                      <Button
                        sx={{ color: "inherit" }}
                        onClick={() => {
                          handleOpenReportModal();
                        }}
                      >
                        <Box
                          sx={{
                            userSelect: "none",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: 20,
                            backgroundColor: theme.palette.secondary.main,
                            width: "fit-content",
                            border: "2px solid white",
                          }}
                        >
                          <Typography variant="button" marginX="8px">
                            Download
                          </Typography>
                        </Box>
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          ) : (
            // just blocked
            <React.Fragment>
              <Divider />
              <Grid container>
                <Grid item xs={8}>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left">
                    <CaseActionMenu kiosk={!!kiosk} case={props.case} type="case" />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="right">
                    <Button
                      sx={{ color: "inherit" }}
                      onClick={() => {
                        setButtonClicked(true);
                        !isAssigned.toUser ? handleClaim() : handleComplete();
                      }}
                      disabled={buttonClicked || isEditing || caseStatus === "loading"}
                    >
                      <Box
                        sx={{
                          userSelect: "none",
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: 20,
                          backgroundColor: !isAssigned.toUser ? theme.palette.secondary.main : theme.palette.info.main,
                          width: "fit-content",
                          border: "2px solid white",
                        }}
                      >
                        <Typography variant="button" marginX="8px">
                          {!isAssigned.toUser ? "claim" : "complete"}
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

export default CaseActionsView;
