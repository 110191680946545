import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import theme from "../../theme";
import Navbar from "../views/Navbar";
import { useDispatch, useSelector } from "../../store";
import { setRerouteString } from "../../slices/irStudy";

const Root = styled.div`
  background-color: ${theme.palette.background.default};
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  padding-top: 64px;
`;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
`;

const Content = styled.div`
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
`;

const MainLayout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rerouteString } = useSelector((store) => store.irStudy);

  useEffect(() => {
    if (rerouteString.includes("studies/") || rerouteString.includes("interesting-cases/")) {
      navigate(rerouteString);
      dispatch(setRerouteString(""));
    }
  }, [dispatch, navigate, rerouteString]);

  return (
    <Root>
      <Navbar />
      <Wrapper>
        <Container>
          <Content>
            <Outlet />
          </Content>
        </Container>
      </Wrapper>
    </Root>
  );
};

export default MainLayout;
