import theme from "../theme";

const getColorByCaseStatus = (statusId: number): string => {
  switch (statusId) {
    case 1: {
      return theme.palette.text.secondary; // light gray
    }
    case 2: {
      return theme.palette.quaternary.main; // blue
    }
    case 3: {
      return theme.palette.success.main; // green
    }
    case 4: {
      return theme.palette.warning.main; // yellow
    }
    case 5: {
      return theme.palette.secondary.main; // orange
    }
    case 6: {
      return theme.palette.tertiary.main; // pink
    }
    default: {
      return theme.palette.text.secondary;
    }
  }
};

export default getColorByCaseStatus;
