import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";
import Option from "../../types/option";
import { useDispatch, useSelector } from "../../store";
import theme from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { fetchCriticalValues, updateValuesList } from "../../slices/cases";

interface Props {
  onSubmit: (result: Option[]) => void;
  criticalOptions?: Option[];
}

const CriticalValue: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { criticalValues } = useSelector((store) => store.cases);

  const [loading, setLoading] = useState<boolean>(false);
  const [addNew, setAddNew] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchCriticalValues());
  }, [dispatch]);

  const handleRemove = (details: Option) => {
    const filterDetails = criticalValues?.filter((c) => c.value !== details.value);
    dispatch(updateValuesList(filterDetails!));
  };

  const handleOpenAdd = () => {
    setAddNew(true);
  };

  const handleAdd = (values: Option) => {
    const filterDetails = [values, ...criticalValues!];
    dispatch(updateValuesList(filterDetails));
    setAddNew(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    props.onSubmit(criticalValues!);
    setLoading(false);
  };

  const modalValidationSchema = Yup.object().shape({
    value: Yup.string().nullable().required("Please enter a new critical value"),
  });

  const initialValues = {
    values: criticalValues,
  };

  return (
    <React.Fragment>
      <Modal open={addNew} closeAfterTransition={true} onClose={() => setAddNew(false)}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Paper style={{ overflow: "hidden", padding: 10 }}>
            <Formik
              enableReinitialize
              initialValues={{ id: 0, value: "" }}
              validationSchema={modalValidationSchema}
              onSubmit={(values) => {
                handleAdd(values);
              }}
            >
              <Form noValidate style={{ color: "textSecondary" }}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                  <Field name="value" required label="Add New Critical Value" component={Inputs.Text} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Box sx={{ mt: 2 }}>
                    <Button
                      color="secondary"
                      size="large"
                      type="button"
                      variant="contained"
                      onClick={() => setAddNew(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Box>
                    <Inputs.Submit text={"Add"} isSubmitting={false} />
                  </Box>
                </Box>
              </Form>
            </Formik>
          </Paper>
        </Box>
      </Modal>
      <Box>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form noValidate style={{ color: "textSecondary" }}>
            <Box
              sx={{
                height: "300px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box sx={{ overflowY: "scroll", fontSize: `${theme.typography.caption}` }}>
                  <Grid container>
                    <Grid
                      item
                      md={4}
                      sm={6}
                      xs={12}
                      style={{ display: "flex", flexDirection: "row", marginBottom: "10px", alignItems: "center" }}
                    >
                      <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                        <IconButton sx={{ color: theme.palette.secondary.main }} onClick={() => {}} color="inherit">
                          <FontAwesomeIcon icon={faPlusCircle} onClick={handleOpenAdd} size="sm" />
                        </IconButton>
                      </div>
                      <li style={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ marginTop: "6px" }}>Add New</Typography>
                      </li>
                    </Grid>
                    {criticalValues && criticalValues.length > 0 ? (
                      criticalValues.map((details) => {
                        return (
                          <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12}
                            key={details?.id}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "10px",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                              <IconButton
                                sx={{ color: theme.palette.secondary.main }}
                                onClick={() => {
                                  handleRemove(details);
                                }}
                                color="inherit"
                              >
                                <FontAwesomeIcon icon={faMinusCircle} size="sm" />
                              </IconButton>
                            </div>
                            <li key={details?.id} style={{ display: "flex", flexDirection: "column" }}>
                              <Typography sx={{ marginTop: "6px" }}>{details.value}</Typography>
                            </li>
                          </Grid>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Inputs.Submit text="Save" isSubmitting={loading} />
            </Box>
          </Form>
        </Formik>
      </Box>
    </React.Fragment>
  );
};

export default CriticalValue;
