import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "../../store";
import { handleSuccessToastState } from "../../slices/toast";
import User from "../../types/user";

interface Props {
  handleClose: () => void;
  loggedInUser: User;
}

const ConnectionChat: React.FC<Props> = (props) => {
  const { handleClose, loggedInUser } = props;
  const dispatch = useDispatch();

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const chatUrl = `${process.env.REACT_APP_IFRAME_URL}`;
    const iframeElement = iframeRef.current;

    const sendMessageToFrame = (message: object) => {
      if (iframeElement?.contentWindow) {
        iframeElement.contentWindow.postMessage(message, "*");
      }
    };

    const openForm = () => {
      let phoneNumber = loggedInUser?.phone;
      if (phoneNumber?.length! > 10 && phoneNumber?.startsWith("1")) {
        phoneNumber = phoneNumber.slice(1);
      }

      const fields = {
        patientName: "",
        dob: "",
        exam: null,
        facility: null,
        referringProvider: "",
        priority: null,
        notes: "",
        radiologistName: loggedInUser ? `${loggedInUser.firstName} ${loggedInUser.lastName}` : "",
        radiologistCallbackNumber: phoneNumber || "",
      };

      sendMessageToFrame({ type: "action", action: "openForm", fields });
    };

    const closeForm = () => {
      iframeElement?.removeEventListener("load", handleIframeLoad);
      window.removeEventListener("message", handleIframeResponse);
      sendMessageToFrame({ type: "action", action: "closeForm" });
      handleClose();
    };

    const handleIframeLoad = () => {
      setTimeout(() => {
        openForm();
      }, 500);
    };

    const handleIframeResponse = (event: MessageEvent) => {
      const { type, result, error } = event.data || {};
      if (type === "submit") {
        if (result === "success") {
          dispatch(handleSuccessToastState(true));
          closeForm();
        } else {
          console.error("Failed:", error);
        }
      }
    };

    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", chatUrl, true);

    xmlHttp.onload = () => {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200 && iframeElement) {
        iframeElement.src = chatUrl;
        iframeElement.addEventListener("load", handleIframeLoad);
      }
    };

    xmlHttp.onerror = function () {
      console.error(xmlHttp.statusText);
    };

    xmlHttp.send(null);

    window.addEventListener("message", handleIframeResponse);

    return () => {
      console.log("returning");
      closeForm();
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="700px">
      <Typography sx={{ fontSize: 13 }}>For AHC support, please call (888) 520-7237</Typography>
      <iframe
        id="requested-work-frame"
        ref={iframeRef}
        title="Requested Work Frame"
        style={{ width: "100%", height: "100%", border: "none" }}
      ></iframe>
    </Box>
  );
};

export default ConnectionChat;
