import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
  Modal,
  Paper,
  Grid,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "../../store";
import { useNavigate } from "react-router-dom";
import SearchParams from "../../types/searchParams";
import { deleteUser, forceLogout, searchUsers, toggleActive } from "../../slices/users";
import { debounce } from "lodash";
import FilterDropdown from "../base/FilterDropdown";
import SearchBar from "../base/SearchBar";
import { Add, Check, Dangerous, Delete, Download, EditRounded, LockOpen, Logout } from "@mui/icons-material";
import { getReport } from "../../slices/report";
import theme from "../../theme";
import Chip from "../base/Chip";
import Option from "../../types/option";
import Moment from "react-moment";
import { resetUser } from "../../slices/addedituser";
import * as Yup from "yup";
import { handleSuccessToastState } from "../../slices/toast";
import { Field, Form, Formik } from "formik";
import { adminChangePassword } from "../../slices/auth";
import { Inputs } from "../forms";
import SnackBarToast from "../base/SnackbarToast";
import StyledTable from "../base/StyledTable";

interface DeleteModal {
  visible: boolean;
  id: number | null;
}

interface Props {}

const Users: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { status: userStatus, searchResults: users } = useSelector((store) => store.users);
  const { status, loggedInUser } = useSelector((store) => store.auth);
  const { successToastOpen } = useSelector((store) => store.toast);
  const { reportStatus } = useSelector((store) => store.reports);
  const [online, setOnline] = useState(false);
  const [filterForm, setFilterForm] = useState<SearchParams>({
    pageNumber: 1,
    pageSize: 10,
    filters: ["active:3"],
    orderDirection: "Ascending",
  });
  const [toggleActiveModal, setToggleActiveModal] = useState({
    visible: false,
    userId: 0,
    active: false,
  });

  const [deleteModal, setDeleteModal] = useState<DeleteModal>({
    visible: false,
    id: null,
  });

  useEffect(() => {
    dispatch(
      searchUsers({
        pageNumber: 1,
        pageSize: 10,
        filters: ["active:3"],
        orderDirection: "Ascending",
      })
    );
  }, [dispatch]);

  const headers = ["", "User", "Roles", "Time", "Actions"];

  const rows =
    users?.results.map((user) => {
      return {
        onlineIcon: user.online && (
          <Chip key={user.id} type="hospital" color={theme.palette.success.main} label="Online" />
        ),
        user: (
          <Box>
            <Typography color="inherit" variant="subtitle1">
              {user.firstName} {user.lastName}
            </Typography>
            <Typography color="textPrimary" variant="body1" style={{ marginBottom: "8px" }}>
              {user.email}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {user.specialty ? "Specialty" : ""}
            </Typography>
            <Typography color="textPrimary" variant="body2">
              {user.specialty}
            </Typography>
          </Box>
        ),
        roles: (
          <Box>
            <Box>
              {user &&
                user.roles &&
                user.roles.map((role: Option, index: number) => (
                  <Typography key={index} color="textPrimary" variant="body2">
                    {role.value}
                  </Typography>
                ))}
            </Box>
          </Box>
        ),
        time: (
          <Box>
            <Typography color="textSecondary" variant="caption">
              First Session
            </Typography>
            <Typography
              color="textPrimary"
              sx={{ typography: { xs: "caption", md: "body1" } }}
              style={{ marginBottom: "8px" }}
            >
              <Moment format={sm ? "MM/DD/YYYY hh:mm:a" : undefined}>{user.firstSession}</Moment>
            </Typography>
            <Typography color="textSecondary" variant="caption">
              Last Session
            </Typography>
            <Typography color="textPrimary" sx={{ typography: { xs: "caption", md: "body1" } }}>
              <Moment format={sm ? "MM/DD/YYYY hh:mm:a" : undefined}>{user.lastSession}</Moment>
            </Typography>
          </Box>
        ),
        actions: (
          <Box>
            {user.active && (
              <Tooltip title={<Typography variant="button">Edit</Typography>} placement="top">
                <IconButton color="inherit" onClick={() => handleEditUser(user?.id || 0)}>
                  <EditRounded />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={<Typography variant="button">Delete</Typography>} placement="top">
              <IconButton onClick={() => handleOpenDeleteModal(user?.id || 0)} color="inherit">
                <Delete />
              </IconButton>
            </Tooltip>
            {user.active && (
              <Tooltip title={<Typography variant="button">Reset Password</Typography>} placement="top">
                <IconButton onClick={() => handleOpenResetPasswordModal(user?.id || 0)} color="inherit">
                  <LockOpen />
                </IconButton>
              </Tooltip>
            )}
            {user.active ? (
              <Tooltip title={<Typography variant="button">Disable User</Typography>} placement="top">
                <IconButton onClick={() => handleOpenToggleActiveModal(user?.id!, false)} color="inherit">
                  <Dangerous />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={<Typography variant="button">Enable User</Typography>} placement="top">
                <IconButton onClick={() => handleOpenToggleActiveModal(user?.id!, true)} color="inherit">
                  <Check />
                </IconButton>
              </Tooltip>
            )}
            {user.online && user.id !== loggedInUser?.id && (
              <Tooltip title={<Typography variant="button">Force Logout</Typography>} placement="top">
                <IconButton onClick={() => handleForceLogout(user.id)} color="inherit">
                  <Logout />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
      };
    }) || [];

  const activeOptions = [
    { id: 1, value: "Active" },
    { id: 2, value: "Disabled" },
    { id: 3, value: "All" },
  ];

  const sortOptions = [
    {
      id: 1,
      value: "First Name",
    },
    {
      id: 2,
      value: "First Name Descending",
    },
    {
      id: 3,
      value: "Last Name",
    },
    {
      id: 4,
      value: "Last Name Descending",
    },
    {
      id: 5,
      value: "Recently Created",
    },
    {
      id: 6,
      value: "Email",
    },
  ];

  const roleOptions = [
    { id: 1, value: "Admin" },
    { id: 2, value: "Radiologist" },
    { id: 3, value: "Technologist" },
    { id: 6, value: "Judge" },
    { id: 7, value: "IR Studies" },
  ];

  const handleSearchClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(searchUsers(filterForm));
  };

  const updateSearch = debounce((query: string) => {
    setFilterForm({ ...filterForm, pageNumber: 1, pageSize: 10, query: query });
    dispatch(searchUsers({ ...filterForm, pageNumber: 1, pageSize: 10, query: query }));
  }, 600);

  const handleFiltering = (e: Option, filterName: string) => {
    let filters = filterForm?.filters;
    let otherFilters = filters?.filter((f) => f.split(":")[0] !== filterName);

    if (e) {
      let newFilters: string[] = [`${filterName}:${e.id}`];
      let newList = otherFilters ? newFilters.concat(otherFilters) : newFilters;
      setFilterForm({ ...filterForm, pageNumber: 1, pageSize: 10, filters: newList });
      dispatch(searchUsers({ pageNumber: 1, pageSize: 10, filters: newList }));
    } else {
      setFilterForm({ ...filterForm, pageNumber: 1, pageSize: 10, filters: otherFilters });
      dispatch(searchUsers({ pageNumber: 1, pageSize: 10, filters: otherFilters }));
    }
  };

  const handleSort = (e: Option) => {
    if (e) {
      switch (e.id) {
        case 1:
          dispatch(
            searchUsers({
              ...filterForm,
              orderBy: "firstName",
              orderDirection: "Ascending",
            })
          );
          setFilterForm({ ...filterForm, orderBy: "firstName", orderDirection: "Ascending" });
          break;
        case 2:
          dispatch(
            searchUsers({
              ...filterForm,
              orderBy: "firstName",
              orderDirection: "Descending",
            })
          );
          setFilterForm({ ...filterForm, orderBy: "name", orderDirection: "Descending" });
          break;
        case 3:
          dispatch(
            searchUsers({
              ...filterForm,
              orderBy: "lastName",
              orderDirection: "Ascending",
            })
          );
          setFilterForm({ ...filterForm, orderBy: "name", orderDirection: "Ascending" });
          break;
        case 4:
          dispatch(
            searchUsers({
              ...filterForm,
              orderBy: "lastName",
              orderDirection: "Descending",
            })
          );
          setFilterForm({ ...filterForm, orderBy: "name", orderDirection: "Descending" });
          break;
        case 5:
          dispatch(
            searchUsers({
              ...filterForm,
              orderBy: "created",
              orderDirection: "Descending",
            })
          );
          setFilterForm({ ...filterForm, orderBy: "created", orderDirection: "Descending" });
          break;
        case 6:
          dispatch(
            searchUsers({
              ...filterForm,
              orderBy: "email",
              orderDirection: "Ascending",
            })
          );
          setFilterForm({ ...filterForm, orderBy: "email", orderDirection: "Ascending" });
          break;
        default:
          break;
      }
    } else {
      dispatch(
        searchUsers({
          ...filterForm,
          orderBy: "",
          orderDirection: "Ascending",
        })
      );
      setFilterForm({ ...filterForm, orderBy: "", orderDirection: "Ascending" });
    }
  };

  const changePage = (pageNumber: number, pageSize: number) => {
    dispatch(
      searchUsers({
        ...filterForm,
        pageNumber: pageNumber,
        pageSize: pageSize,
      })
    ).then(() => {
      setFilterForm({ ...filterForm, pageNumber: pageNumber, pageSize: pageSize });
    });
  };

  const handleDownload = (reportUrl: string) => {
    dispatch(getReport({ reportUrl: reportUrl }));
  };

  const handleAddUser = () => {
    dispatch(resetUser());
    navigate("/app/profile");
  };

  const handleEditUser = (userId: number) => {
    navigate("/app/profile", {
      state: {
        id: userId,
        fromSettings: true,
      },
    });
  };

  const handleOpenToggleActiveModal = (id: number, active: boolean) =>
    setToggleActiveModal({
      visible: true,
      userId: id,
      active: active,
    });

  const handleOpenDeleteModal = (id: number) =>
    setDeleteModal({
      visible: true,
      id: id,
    });

  const handleCloseDeleteModal = () => {
    setDeleteModal((prevState) => {
      return {
        ...prevState,
        visible: false,
      };
    });
    setTimeout(() => {
      setDeleteModal((prevState) => {
        return {
          ...prevState,
          id: null,
        };
      });
    }, 250);
  };

  const handleDeleteUser = async () => {
    handleCloseDeleteModal();
    await dispatch(deleteUser(deleteModal?.id || 0));
    dispatch(
      searchUsers({
        pageNumber: 1,
        pageSize: 10,
        filters: ["active:1"],
        orderDirection: "Ascending",
      })
    );
  };

  const handleDisableUser = async () => {
    handleCloseToggleActiveModal();
    await dispatch(toggleActive({ id: toggleActiveModal?.userId, active: toggleActiveModal?.active }));
    dispatch(
      searchUsers({
        pageNumber: 1,
        pageSize: 10,
        filters: ["active:1"],
        orderDirection: "Ascending",
      })
    );
  };

  const handleForceLogout = async (id: number) => {
    await dispatch(forceLogout(id)).then(() => {
      dispatch(
        searchUsers({
          pageNumber: 1,
          pageSize: 10,
          filters: ["active:1"],
          orderDirection: "Ascending",
        })
      );
    });
  };

  const [resetPasswordModal, setResetPasswordModal] = useState({
    visible: false,
    userId: 0,
  });

  const handleOpenResetPasswordModal = (userId: number) => {
    setResetPasswordModal({
      visible: true,
      userId: userId,
    });
  };

  const handleCloseResetPasswordModal = () => {
    setResetPasswordModal((prevState) => {
      return {
        ...prevState,
        visible: false,
      };
    });
    setTimeout(() => {
      setResetPasswordModal((prevState) => {
        return {
          ...prevState,
          username: "",
        };
      });
    }, 250);
  };

  const handleCloseToggleActiveModal = () => {
    setToggleActiveModal((prevState) => {
      return {
        ...prevState,
        visible: false,
      };
    });
    setTimeout(() => {
      setToggleActiveModal((prevState) => {
        return {
          ...prevState,
          userId: 0,
          active: false,
        };
      });
    }, 250);
  };

  const validationSchema = Yup.object().shape(
    {
      password: Yup.string()
        .min(8)
        .max(128)
        .required("Please enter your new password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, and One Number"
        ),
      confirmPassword: Yup.string().when("password", {
        is: (val: string) => val && val.length > 0,
        then: Yup.string()
          .required("Please confirm password")
          .oneOf([Yup.ref("password")], "Passwords must match"),
      }),
    },
    [["password", "confirmPassword"]]
  );

  const closeToast = () => {
    dispatch(handleSuccessToastState(false));
  };

  const handleOnlineSwitch = (online: boolean) => {
    var filter = { id: online ? 1 : 2, value: "" };
    handleFiltering(filter, "online");
  };

  return (
    <Box>
      <Modal open={deleteModal.visible} closeAfterTransition={true} onClose={handleCloseDeleteModal}>
        <Fade in={deleteModal.visible}>
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: sm ? 350 : 650,
            }}
          >
            <Paper style={{ overflow: "hidden" }}>
              <Grid margin={2}>
                <Typography>
                  Are you sure you would like to delete "
                  {(() => {
                    const foundUser = users?.results?.find((user) => user?.id === deleteModal?.id);
                    return `${foundUser?.firstName} ${foundUser?.lastName}`;
                  })()}
                  "?
                </Typography>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                  <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="secondary"
                        fullWidth
                        size="large"
                        type="button"
                        variant="contained"
                        onClick={handleCloseDeleteModal}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleDeleteUser}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>
      <Modal closeAfterTransition={true} open={toggleActiveModal.visible} onClose={handleCloseToggleActiveModal}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: sm ? 350 : 500,
          }}
        >
          <Paper style={{ overflow: "hidden", padding: 10 }}>
            <Grid margin={2}>
              <Typography>
                Are you sure you would like to {toggleActiveModal.active ? "re-enable " : "disable "}
                {(() => {
                  const foundUser = users?.results?.find((user) => user?.id === toggleActiveModal?.userId);
                  return `${foundUser?.firstName} ${foundUser?.lastName}`;
                })()}
                ?
              </Typography>
              <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={6}>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      color="secondary"
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      onClick={handleCloseToggleActiveModal}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={handleDisableUser}
                    >
                      {toggleActiveModal.active ? "Enable" : "Disable"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
      <Modal open={resetPasswordModal.visible} closeAfterTransition={true} onClose={handleCloseResetPasswordModal}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: sm ? 350 : 500,
          }}
        >
          <Paper style={{ overflow: "hidden", padding: 10 }}>
            <Formik
              enableReinitialize
              initialValues={{ userId: resetPasswordModal.userId, password: "" }}
              validationSchema={validationSchema}
              onSubmit={async (values, actions) => {
                let newValues = {
                  userId: values.userId,
                  password: values.password,
                };
                handleCloseResetPasswordModal();
                await dispatch(adminChangePassword(newValues)).then((result) => {
                  if (result.meta.requestStatus === "fulfilled") {
                    dispatch(handleSuccessToastState(true));
                  }
                });
              }}
            >
              {({ values, setFieldValue }) => {
                return (
                  <Form noValidate style={{ color: "textSecondary" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                      <Typography variant="h6" align="left">
                        Please enter new password below. Password must be a minimum of 8 characters and contain one
                        uppercase letter, one lowercase letter, and one number.
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                      <Field name="password" required label="New Password" component={Inputs.Password} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                      <Field name="confirmPassword" required label="Confirm Password" component={Inputs.Password} />
                    </Box>
                    <Box
                      sx={{
                        // mt: "12px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Box sx={{ mt: 2 }}>
                        <Button
                          color="secondary"
                          size="large"
                          type="button"
                          variant="contained"
                          onClick={handleCloseResetPasswordModal}
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Box>
                        <Inputs.Submit text={"Save"} isSubmitting={status === "loading"} />
                      </Box>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Box>
      </Modal>

      <SnackBarToast message="Success!" type="success" open={successToastOpen} handleClose={closeToast} />
      <Paper>
        <Grid container padding="15px" direction={"row"} alignItems="center" justifyContent={"space-between"}>
          <Grid item padding={1}>
            <Typography variant={"h6"}>Add/Edit Users</Typography>
          </Grid>
          <Grid item>
            <Button onClick={handleAddUser} color="primary" startIcon={<Add fontSize="small" />} variant="contained">
              New User
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <form onSubmit={handleSearchClick}>
          <Grid
            container
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            padding="15px"
            spacing={1}
          >
            <Grid item md={3} xs={12}>
              <SearchBar value="Search Users" variant="body2" handleChange={updateSearch} />
            </Grid>
            <Grid item md={3} xs={12}>
              <FilterDropdown
                label="Sort By"
                sortOptions={sortOptions}
                handleChange={(e: Option) => {
                  handleSort(e);
                }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <FilterDropdown
                label="Filter by State"
                sortOptions={activeOptions}
                handleChange={(e: Option) => {
                  handleFiltering(e, "active");
                }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <FilterDropdown
                label="Filter by Role"
                sortOptions={roleOptions}
                handleChange={(e: Option) => {
                  handleFiltering(e, "role");
                }}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Online Now"
                control={
                  <Switch
                    checked={online}
                    onChange={(e) => {
                      handleOnlineSwitch(e.target.checked);
                      setOnline(!online);
                    }}
                    color="primary"
                    name="online"
                  />
                }
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  handleDownload("reports/users");
                }}
                startIcon={
                  reportStatus === "loading" ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <Download fontSize="small" />
                  )
                }
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </form>
        {userStatus === "loading" ? (
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", padding: 3 }}>
            <CircularProgress color="inherit" size={50} />
          </Box>
        ) : (
          <Box>
            <StyledTable
              headers={headers}
              rows={rows}
              paging={true}
              page={filterForm.pageNumber}
              rowsPerPage={filterForm.pageSize}
              totalRows={users?.totalResults}
              changePage={changePage}
              useObjects
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default Users;
