import { Box, Button, Divider, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../theme";
import { Add, Delete, EditRounded, Visibility } from "@mui/icons-material";
import Modal from "../base/Modal";
import { useDispatch, useSelector } from "../../store";
import StyledTable from "../base/StyledTable";
import SearchParams from "../../types/searchParams";
import IrStudy from "../../types/irStudy";
import Chip from "../base/Chip";
import DeleteConfirmation from "../base/DeleteConfirmation";
import ChangeStudyStatus from "./ChangeStudyStatus";
import Option from "../../types/option";
import {
  deleteInterestingCase,
  editInterestingCase,
  getInterestingCase,
  getUnreadCases,
  readCase,
  resetInterestingCase,
  saveInterestingCase,
  searchInterestingCases,
} from "../../slices/interestingCases";
import InterestingCase from "../../types/interestingCase";
import AddInterestingCase from "./AddInterestingCase";
import { handleSuccessToastState } from "../../slices/toast";
import SnackBarToast from "../base/SnackbarToast";
import getUserRoleId from "../../helpers/getUserRoleId";

interface Props {
  id?: number;
}

const InterestingCasesTable: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { id } = props;
  const { interestingCase, cases, status } = useSelector((store) => store.interestingCases);
  const { loggedInUser } = useSelector((store) => store.auth);
  const { successToastOpen } = useSelector((store) => store.toast);

  const [openCase, setOpenCase] = useState<number>(id || 0);
  const [filterForm, setFilterForm] = useState<SearchParams>({
    pageNumber: 1,
    pageSize: 10,
  });

  type ActiveModal = "none" | "view" | "add" | "edit" | "delete";
  const [modalOpen, setModalOpen] = useState<ActiveModal>("none");

  useEffect(() => {
    if (id) {
      handleOpenViewModal(id);
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(searchInterestingCases(filterForm));
  }, [dispatch, filterForm]);

  const handleOpenViewModal = (id: number) => {
    dispatch(getInterestingCase(id)).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        setModalOpen("view");
      }
    });
  };

  const handleOpenAddModal = () => {
    setModalOpen("add");
    dispatch(resetInterestingCase());
  };

  const handleOpenEditModal = async (id: number) => {
    setOpenCase(id);
    await dispatch(getInterestingCase(id));
    setModalOpen("edit");
  };

  const handleOpenDeleteModal = (id: number) => {
    setOpenCase(id);
    setModalOpen("delete");
  };

  const handleCloseModal = () => {
    dispatch(searchInterestingCases(filterForm));
    setModalOpen("none");
    setOpenCase(0);
  };

  const closeToast = () => {
    dispatch(handleSuccessToastState(false));
  };

  const headers = ["", "MR Number", "Hospital", "Modality", "Added By", "Actions"];

  const rows =
    cases?.results.map((interestingCase: InterestingCase) => {
      return {
        read:
          interestingCase && !interestingCase.read ? (
            <Chip key={interestingCase.id} type="notification" color={theme.palette.success.main} label="Unread" />
          ) : (
            <Box></Box>
          ),
        mrNumber: interestingCase.mrNumber,
        hospital: interestingCase.hospital?.name,
        modality: interestingCase.modality?.name,
        addedBy: `${interestingCase.submittedUser?.firstName} ${interestingCase.submittedUser?.lastName}`,
        actions: (
          <Box>
            <Tooltip title={<Typography variant="button">View</Typography>} placement="top">
              <IconButton onClick={() => handleOpenViewModal(interestingCase.id)} color="inherit">
                <Visibility />
              </IconButton>
            </Tooltip>
            {!!loggedInUser?.roles.find((role) => role.id === 1) && (
              <Tooltip title={<Typography variant="button">Delete</Typography>} placement="top">
                <IconButton onClick={() => handleOpenDeleteModal(interestingCase.id)} color="inherit">
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
      };
    }) || [];

  const changePage = (pageNumber: number, pageSize: number) => {
    setFilterForm({ ...filterForm, pageNumber: pageNumber, pageSize: pageSize });
  };

  const handleSubmit = async (model: IrStudy) => {
    await dispatch(saveInterestingCase(model)).then((response: any) => {
      dispatch(readCase(response.payload.id)).then(() => {
        dispatch(searchInterestingCases(filterForm));
        dispatch(getUnreadCases());
      });
    });
    setModalOpen("none");
    dispatch(handleSuccessToastState(true));
  };

  const handleChangeCaseStatus = async (status: Option) => {
    await dispatch(editInterestingCase({ ...interestingCase, status: status }));
    handleCloseModal();
  };

  const handleDelete = async () => {
    await dispatch(deleteInterestingCase(openCase));
    handleCloseModal();
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        minHeight: "100%",
        py: 3,
        m: 3,
      }}
    >
      <Paper>
        <Grid container padding="15px" direction={"row"} alignItems="center" justifyContent={"space-between"}>
          <Grid item padding={1}>
            <Typography variant={"h6"}>Interesting Cases</Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={handleOpenAddModal}
              color="primary"
              startIcon={<Add fontSize="small" />}
              variant="contained"
              disabled={status === "loading"}
            >
              New Case
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <Box>
          <StyledTable
            headers={headers}
            rows={rows}
            paging={true}
            page={filterForm.pageNumber}
            rowsPerPage={filterForm.pageSize}
            totalRows={cases?.totalResults}
            changePage={changePage}
            useObjects
          />
        </Box>
      </Paper>
      <Modal
        open={modalOpen === "add" || modalOpen === "view"}
        handleClose={handleCloseModal}
        title={modalOpen === "view" ? "View Interesting Case" : "New Interesting Case"}
      >
        <AddInterestingCase handleClose={handleCloseModal} onSubmit={handleSubmit} />
      </Modal>
      <Modal open={modalOpen === "delete"} handleClose={handleCloseModal} title={"Confirm Delete"}>
        <DeleteConfirmation name="this case" handleClose={handleCloseModal} handleDelete={handleDelete} />
      </Modal>
      <Modal open={modalOpen === "edit"} handleClose={handleCloseModal} title={"Change Status"}>
        <ChangeStudyStatus
          status={interestingCase.status!}
          handleClose={handleCloseModal}
          handleSubmit={handleChangeCaseStatus}
        />
      </Modal>
      <SnackBarToast message="Success!" type="success" open={successToastOpen} handleClose={closeToast} />
    </Box>
  );
};

export default InterestingCasesTable;
