import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, Container, Card, CardContent, Divider, Typography } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Inputs } from "../forms";
import LoginType from "../../types/login";
import { useLocation, useNavigate } from "react-router-dom";
import SnackBarToast from "../base/SnackbarToast";
import { check, login as loginAction } from "../../slices/auth";
import theme from "../../theme";
import { handleSuccessToastState } from "../../slices/toast";
import { setRerouteString } from "../../slices/irStudy";
import Cookies from "universal-cookie";
import AuthLogo from "../base/AuthLogo";

interface Props {}

const Login: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const { state } = useLocation();

  const { successToastOpen } = useSelector((store) => store.toast);
  const { temporaryPassword, passwordExpired, status, errorMessage } = useSelector((store) => store.auth);

  const [errorToast, setErrorToast] = useState(false);
  const [userLogin, setUserLogin] = useState<LoginType>({ username: "", password: "", rememberMe: false });
  const [formReady, setFormReady] = useState<boolean>(false);

  useEffect(() => {
    if (cookies.get("rememberMe")) {
      setUserLogin({
        username: cookies.get("rememberMe"),
        password: "",
        rememberMe: true,
      });
    }
    setFormReady(true);
  }, []);

  useEffect(() => {
    if (state) {
      if (state.redirectPath) {
        dispatch(setRerouteString(state.redirectPath));
      }
    }
  }, [state, dispatch]);

  const closeToast = () => {
    dispatch(handleSuccessToastState(false));
  };

  const closeErrorToast = () => {
    setErrorToast(false);
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().email("Invalid email").required("Please enter your username"),
    password: Yup.string().required("Please enter your password"),
  });

  return formReady ? (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: { xs: "100%", md: "100vh" },
        }}
      >
        <Container maxWidth="sm" sx={{ py: { md: "80px", xs: "0px" }, pb: { xs: "20px", md: "0px" } }}>
          <AuthLogo />
          <Card sx={{ mt: "15px" }}>
            <CardContent>
              <Box>
                <Typography color="textPrimary" variant="h4">
                  Sign In
                </Typography>
                <Formik
                  initialValues={userLogin}
                  validationSchema={validationSchema}
                  onSubmit={async (values, actions) => {
                    await dispatch(loginAction(values));
                    const current = new Date();
                    const nextYear = new Date();
                    nextYear.setFullYear(current.getFullYear() + 1);
                    if (userLogin.rememberMe) {
                      cookies.set("rememberMe", values.username, {
                        path: "/",
                        expires: nextYear,
                      });
                    } else {
                      cookies.remove("rememberMe");
                    }
                    if (!!temporaryPassword || !!passwordExpired) {
                      navigate(`/password/change?username=${values.username}`);
                    } else {
                      dispatch(check(true)).then((response) => {
                        if (response && response.payload) {
                          navigate("/app/dashboard");
                        } else {
                          setErrorToast(true);
                        }
                      });
                    }
                  }}
                >
                  <Form noValidate>
                    <Field name="username" label="Email" required component={Inputs.Text} />
                    <Field name="password" label="Password" required component={Inputs.Password} />
                    <Field
                      component={Inputs.RememberMe}
                      name="rememberMe"
                      label="Remember Me"
                      checked={userLogin.rememberMe}
                      onChange={(e: boolean) => {
                        setUserLogin({ ...userLogin, rememberMe: e });
                      }}
                    />
                    <Inputs.Error hasError={status === "failed"} errorMessage={errorMessage} />
                    <Inputs.Submit text="Sign In" isSubmitting={status === "loading"} />
                  </Form>
                </Formik>
                <Divider sx={{ my: 3 }} />
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", mx: 1, pb: 1 }}>
                  <Typography
                    variant="body2"
                    fontStyle="italic"
                    color={theme.palette.text.primary}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/forgot")}
                  >
                    Forgot Password?
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <SnackBarToast message="Success!" type="success" open={successToastOpen} handleClose={closeToast} />
      <SnackBarToast
        message="Incorrect email or password"
        type="error"
        open={errorToast}
        handleClose={closeErrorToast}
      />
    </Box>
  ) : (
    <></>
  );
};

export default Login;
